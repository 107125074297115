<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--termination">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--calls"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form form--terminate">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addCallModal.${"title"}`) }}  
            </div>
          </div>
        </div>
        <div class="add__form add__form--terminate">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="termination">
                <div class="termination__row">
                  <div class="termination__column"> 
                    <div class="form__field">  
                      <VSelect
                          v-if="customerMembers.length"
                          class="select select--default"
                          :options="customerMembers"
                          :reduce="(member) => member.id"
                          :getOptionLabel="
                            (member) => parseCustomerName(member)
                          "
                          v-model="customerId"  
                        />
                        
                        <div 
                          v-else
                          class="input input--default input--readonly"
                        >
                          {{ parseCustomerName(customerDetails) }}
                        </div>
                    </div>
                    
                    <div class="form__field date-time-wrapper" > 
                      <div class="date-picker">
                        <Datepicker
                          class="input input--default date-picker" 
                          :class="{ 'select--error': dateHasError }"
                          :config="statics.datePickerConfig"
                          :placeholder="
                            $t(`addCallModal.${'date'}`)
                          "
                          v-model="date" 
                        /> 
                        <div class="form__error" v-if="dateHasError">
                          Please select the date
                        </div>
                      </div>
                      <div class="date-picker">
                        <VueTimepicker 
                          close-on-complete
                          hide-clear-button
                          class="input input--default input time-picker"  
                          :class="{ 'select--error': timeHasError }"
                          v-model="time"
                          placeholder="--  :  --"

                        />
                        <div class="form__error" v-if="timeHasError">
                          Please select the time
                        </div>
                      </div>
                      
                    </div>
                    <div class="form__field"> 
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': categoryHasError }"
                        :options="categories"
                        :reduce="(category) => category.id"
                        :getOptionLabel="
                          (category) => `${category.name_de} `
                        "
                        :placeholder="
                          $t(`addCallModal.${'categoryDropdown'}`)
                        "
                        v-model="callCategoryId"
                      />
                      <div class="form__error" v-if="categoryHasError">
                        Please select a category
                      </div>
                    </div>
                  </div>
                  <div class="termination__column termination__column--middle">
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': agentHasError }"
                        :options="agents"
                        :reduce="(agent) => agent.id"
                        :getOptionLabel="
                          (agent) => `${agent.first_name} ${agent.last_name}`
                        "
                        :placeholder="
                          $t(`addCallModal.${'agentDropdown'}`)
                        "
                        v-model="agentId"
                      />
                      <div class="form__error" v-if="agentHasError">
                        Please select an agent
                      </div>
                    </div>
                    
                     
                    <div class="form__field form__field__call__duration" :class="{ 'select--error': durationHasError }">
                      <div class="call__duration__label">
                        <p><i>{{$t(`addCallModal.${'callDuration'}`)}}</i></p>
                      </div>
                      <div style="display: flex; align-items: center; gap: 9px;">
                        <div class="call__duration__types" >
                          <p 
                            class="call__duration__value" 
                            :class="{ 'call__duration__value--active': durationMinutes == 15 }"  
                            @click="selectCallDuration(15)"
                          >
                            15 min
                          </p>
                          <p 
                            class="call__duration__value" 
                            :class="{ 'call__duration__value--active': durationMinutes == 30 }"  
                            @click="selectCallDuration(30)"
                          >
                            30 min
                          </p>
                          <p 
                            class="call__duration__value" 
                            :class="{ 'call__duration__value--active': durationMinutes == 40 }"  
                            @click="selectCallDuration(40)"
                          >
                            45 min
                          </p>
                        </div>
                        <div style="width: 80px;">  
                          <div class="custom-duration-wrapper" >
                            <input
                              type="number"
                              v-model="hours"
                              min="0"
                              max="59"
                              @input="validateHours"
                              class=" custom-duration"
                              placeholder="HH"
                            />
                            <p><b>:</b></p>
                            <input
                              type="number"
                              v-model="minutes"
                              min="0"
                              max="59"
                              @input="validateMinutes"
                              class=" custom-duration"
                              placeholder="mm"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form__error" v-if="durationHasError">
                        Please select the call duration
                      </div>
                    </div>
                  </div>
                  <div class="termination__column">
                    <DropFile @input="files = $event" />
                  </div>
                </div>
                <div class="termination__row termination__textarea__row">
                  <div class="form__field form__notes__textarea">
                      <textarea
                        :placeholder="$t(`addCallModal.${'text'}`)"
                        cols="30"
                        rows="10"
                        class="input input--default termination__textarea"
                        v-model="description"
                        style="max-width: initial !important;"
                      ></textarea>
                  </div>                  
                </div> 
              </div>
            </div>

   
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <div  v-else>
        <button
          v-if="!isCallEditing"
          class="modal__button modal__button--submit button button--default"
          @click="addCall()"
        >
          <span>{{$t(`addCallModal.${'saveBtn'}`)}}</span>
        </button>

        <button
          v-else
          class="modal__button modal__button--submit button button--default"
          @click="editCall()"
        >
          <span>{{$t(`addCallModal.${'editBtn'}`)}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";
import { mapGetters } from "vuex";
import statics from "@/statics";
export default {
  name: "AddCallsModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {
      parseCustomerName,
      formLoading: true,
      isRequesting: false,
      statics,

      callDuration: null,
      hours : "",
      minutes : "",
      
      agentId: "",
      customerId: "",
      callCategoryId: "",
      date: "",
      time: "00:00",
      description: "",
      durationMinutes: "",
      files: [],

      agentHasError: false,
      dateHasError: false,
      timeHasError: false,
      categoryHasError: false,
      durationHasError: false,
      durationMinutesCustom: '',

      formattedTime: '',
      placeholder: 'Time',



    };
  },

  watch: {
    minutes: "updateCallDuration",
    hours: "updateCallDuration",

    agentId(value) {
      if (value) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
    },
    date(value) {
      if (value) {
        this.dateHasError = false;
      } else {
        this.dateHasError = true;
      }
    },
    time(value) {
      console.log(value);
      if (value && value != "00:00") {
        this.timeHasError = false;
      } else {
        this.timeHasError = true;
      }
    },
    durationMinutesCustom(value){
      if (value) {
        this.durationHasError = false;

      } else {
        this.durationHasError = true;
      }
    },
    durationMinutes(value){
      if (value) {
        this.durationHasError = false;
      } else {
        this.durationHasError = true;
      }
    },
    callCategoryId(value) {
      if (value) {
        this.categoryHasError = false;
      } else {
        this.categoryHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters([
      "customerDetails",  
      "callsForms", 
      "isCallEditing", 
      "editingCallData",
      "customerMembers",
    ]),

    agents() {
      return this.callsForms.agents;
    },

    categories() {
      return this.callsForms.categories;
    }, 
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getCallsForms"); 
    this.formLoading = false;

    this.customerId = this.customerDetails.id;
  },

  async mounted(){ 

  if(this.isCallEditing){   
  
    this.agentId = this.editingCallData.agent.id, 
    this.callCategoryId = this.editingCallData.category.id,
    this.date = this.editingCallData.date,
    this.time = this.editingCallData.time,
    this.description = this.editingCallData.description,
    this.durationMinutes = this.editingCallData.duration_minutes

    if(this.durationMinutes == 15 || this.durationMinutes == 30 || this.durationMinutes == 45){
      this.minutes = "";
      this.seconds = "";
    }else{
      this.minutes = this.durationMinutes;
      this.seconds = 0;
    }
  }
  },

  destroyed() { 
    this.$store.commit("setShowAddCallsModal", false);
    this.$store.commit("setIsCallEditing", false);
    this.$store.commit("setEditingCallData", null); 
  },

  methods: {
    validateMinutes() {
      if(this.durationMinutes){
        this.durationMinutes = "";
      }
      if (this.minutes > 59) {
        this.minutes = 59;
      } else if (this.minutes < 0 || isNaN(this.minutes)) {
        this.minutes = "";
      }
    },

    validateHours() {
      if(this.callDuration){
        this.callDuration = "";
      }
      if (this.hours < 0 || isNaN(this.hours)) {
        this.hours = "";
      }
    },

    updateCallDuration() {
      // const timeString = `${String(this.minutes).padStart(2, "0")}:${String(this.seconds).padStart(2, "0")}`;
      var timeString = 0;
      if(Number(this.hours) && Number(this.hours) > 0){
        timeString = Number(this.hours) * 60;
      }
      if(this.minutes && Number(this.minutes) > 0)
      {
        timeString += Number(this.minutes);
      }
       this.durationMinutesCustom = timeString;
    },

    selectCallDurationFirst(){
      this.seconds = "";
      this.minutes = "";
    },

    selectCallDuration(callDuration){  
      this.seconds = "";
      this.minutes = "";
      this.durationMinutes = callDuration;  
 
    },
 

    closeModal() {
      this.$store.commit("setShowAddCallsModal", false);
    },

    goBack() {
      this.closeModal();
      this.$store.commit("setShowAddActivityModal", true);
    },
 

    validateFields(callback) {
      if (this.agentId) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }

      if (this.date) {
        this.dateHasError = false;
      } else {
        this.dateHasError = true;
      }

      if (this.callCategoryId) {
        this.categoryHasError = false;
      } else {
        this.categoryHasError = true;
      }

      if (this.durationMinutes || this.durationMinutesCustom) {
        this.durationHasError = false;
      } else {
        this.durationHasError = true;
      }

      if (this.time && this.time != "00:00") {
        this.timeHasError = false;
      } else {
        this.timeHasError = true;
      }

      if (
        !this.agentHasError && 
        !this.dateHasError &&
        !this.timeHasError &&
        !this.categoryHasError && 
        !this.durationHasError 
      ) {
        callback(true);
      } else {
        callback(false);
      }
    },

    formatTime(event) {
      const input = event.target.value.replace(/[^0-9]/g, '');
      let formatted = '';
      if (input.length >= 3) {
        formatted = `${input.slice(0, 2)}:${input.slice(2, 4)}`;
      } else if (input.length >= 1) {
        formatted = input;
      }
      this.formattedTime = formatted;
      this.time = formatted;
    },

    formatTimeBeforeSend(time) { 
      let [hours, minutes] = time.split(':');
       
      return `${hours}:${minutes}`;
    },


    async addCall() {
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {  
          try {
            let duration = 0;
            if(Number(this.durationMinutesCustom) > 0){
              duration = this.durationMinutesCustom;
            }else{ 
              duration = this.durationMinutes;
            }  

            const callId = await this.$store.dispatch('createCalls', { 
              agent_id: this.agentId,
              customer_id: this.customerId, 
              call_category_id: this.callCategoryId,
              date: this.date,
              time: this.formatTimeBeforeSend(this.time),
              description: this.description,
              duration_minutes: duration
            });

            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Call created successfully.",
            });
            this.closeModal();   

            if (this.files.length > 0) {  
              const files = this.files.map((file) => file.file);
              await this.$store.dispatch("addCallsFile", {
                callId,
                files,
              });

              await this.$store.dispatch("getCalls", this.$route.params.id);
            }
          } catch (error) {
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to add call: " + error,
            });
          } finally {
            this.isRequesting = false;
          }
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },


    editCall(){
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {   
          let duration = 0;
          if(this.durationMinutesCustom > 0){ 
            duration = this.durationMinutesCustom;
          }else{ 
            duration = this.durationMinutes;
          } 

          this.$store.dispatch('updateCalls', {
            id: this.editingCallData.id,   
            agent_id: this.agentId,
            customer_id: this.customerId,
            call_category_id: this.callCategoryId,
            date: this.date,
            time: this.formatTimeBeforeSend(this.time),
            description: this.description,
            duration_minutes: duration
          }).then(() => {
             
            this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Call edited successfully.",
              });
            this.closeModal();   
          }).catch((error) => { 
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to edit call:" + error,
            });
          });
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_termination-potential-policy.scss";



.custom-duration-wrapper{
   
    background-color: var(--bg-3);
    
    color: var(--text-3);
    padding: 0 10px;
    font-size: var(--x-small);
    border-radius: var(--radius-1);
    font-family: var(--muli-text-semi-bold);
    border: 1px solid transparent;


    display: flex; align-items: center; padding: 0;
 
}

.custom-duration{
  background-color: transparent;
    //  border: 1px solid ;
      height: 40px;
    color: var(--text-3);
 
    font-size: var(--x-small);
    text-align: center;
    font-family: var(--muli-text-semi-bold); 
}

 
::v-deep .vue__time-picker input.display-time { 
  width: 100%; 
  padding: .3em .5em;
  font-size: 1em;
  height: 100%;
  background-color: transparent;
  border: none;
  text-align: center;
  color: var(--text-2);
  font-family: var(--muli-text-semi-bold);
}

.termination__row {
  border: 0;
}

.form__field__call__duration{
  margin-top: -8px;
}

.call__duration__label{ 
  margin-bottom: -6px;
}

.call__duration__label p{
  color: #7F8FA480;
  font-size: 14px;
}

 
.call__duration__types{
  display: flex; 
  gap: 12px;
}

 
.call__duration__value{
  color: #B9C2CD;
  font-size: 16px;
  cursor: pointer;
}


.call__duration__value--active{
  color: #22DB8E;
}

.termination__textarea__row{
  margin-top: -40px;
}

.form__notes__textarea{
  width: 66%;
}


.custom-time-input {
  position: relative;
  width: 48%;
}

.custom-input {
  appearance: none;
  color: var(--text-2);
  background-color: var(--bg-3);
  border-radius: 10px;
  padding:0 10px;
  height: 40px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.custom-input::placeholder {
  font-family: var(--muli-text-semi-bold);
  color: var(--text-8);
}


.date-picker{
  width: 48% ;
  position: relative;
  z-index: 1;
}

.date-time-wrapper{
  display: flex; 
  justify-content: space-between;
}
</style>
